<template>
  <div class="user_yijian">
		<el-card>
			<el-row class="left">
				<el-col :span="24">
					<el-form :inline="true"  class="demo-form-inline">
						<el-form-item label="订单编号">
							<el-input v-model="searchText" placeholder="请输入订单编号" clearable></el-input>
						</el-form-item>
						<el-form-item label="技师名称">
							<el-input v-model="jishi_name" placeholder="请输入技师名称" clearable></el-input>
						</el-form-item>
						<el-form-item label="投诉时间">
							<el-date-picker
					      v-model="datePicker"
					      type="daterange"
					      range-separator="至"
					      start-placeholder="开始日期"
					      end-placeholder="结束日期"
					      value-format="yyyy-MM-dd">
					    </el-date-picker>
						</el-form-item>
						<el-form-item>
							<el-button type="primary" @click="search" icon="el-icon-search">搜索</el-button>
							<el-button type="default" @click="reset" icon="el-icon-refresh">重置</el-button>
						</el-form-item>
					</el-form>
				</el-col>
			</el-row>
			<!-- 服务列表 -->
			<el-table :data="tousu_list" border style="width: 100%" :header-cell-style="{ background: '#eef1f6', color: '#606266' }">
				<el-table-column type="expand">
		      <template slot-scope="props">
		        <el-form label-position="left" inline class="demo-table-expand">
		          <el-row>
					  		<el-col :span="24">
				          <el-form-item label="客户微信：">
				          	<div class="cell el-tooltip">
											<span class="el-avatar el-avatar--square" style="height: 60px; width: 60px; line-height: 60px;margin-left: 10px;">
												<el-image :src="props.row.wx.avatarUrl" fit="fit" :preview-src-list="[props.row.wx.avatarUrl]"></el-image>
											</span>
											<span>{{ props.row.nickName }}</span>
										</div>
				          </el-form-item>
				       	</el-col>
				       	<el-col :span="24">
				          <el-form-item label="服务项目：">
				            <span>{{ props.row.service_name }}</span>
				          </el-form-item>
				       	</el-col>
				       	<el-col :span="24">
				          <el-form-item label="总计：">
				            <span>¥{{ props.row.pay_fee }}</span>
				          </el-form-item>
				       	</el-col>
				       	<el-col :span="24">
				          <el-form-item label="投诉内容：">
				            <span>{{ props.row.content }}</span>
				          </el-form-item>
				        </el-col>
				       	<el-col :span="24">
				          <el-form-item label="投诉图片：">
				            <div class="cell el-tooltip">
											<span v-for="(item,index) in props.row.img_list" :key="index" class="el-avatar el-avatar--square" style="height: 60px; width: 60px; line-height: 60px;margin-left: 10px;">
												<el-image :src="item" fit="fit" :preview-src-list="props.row.img_list"></el-image>
											</span>
										</div>
				          </el-form-item>
				        </el-col>
				        <el-col :span="24">
				          <el-form-item label="回复内容：">
				            <span>{{ props.row.result_content }}</span>
				          </el-form-item>
				        </el-col>
				        <el-col :span="24">
				          <el-form-item label="回复时间：">
				            <span>{{ props.row.result_time }}</span>
				          </el-form-item>
				        </el-col>
				        <el-col :span="24">
				          <el-form-item label="是否撤销：">
				            <span v-if="props.row.is_cancel">已撤销</span>
				          </el-form-item>
				        </el-col>
				      </el-row>
		        </el-form>
		      </template>
		    </el-table-column>
				<el-table-column prop="tousu_id" label="ID" width="80"></el-table-column>
				<el-table-column prop="order_sn" label="订单编号" width="180"></el-table-column>
				<el-table-column label="技师形象照" width="100">
					<template slot-scope="scope">
						<div>
							<el-avatar shape="square" size="large" :src="scope.row.gzxxz_img"></el-avatar>
						</div>
					</template>
				</el-table-column>
				<el-table-column prop="jishi_name" label="技师名称"></el-table-column>
				<el-table-column :show-overflow-tooltip="true" prop="consignee" label="投诉客户"></el-table-column>
				<el-table-column prop="phone" label="联系方式"></el-table-column>
				<el-table-column label="状态" width="80">
					<template slot-scope="scope">
						<div>
							<el-tag type="info" v-if="scope.row.is_cancel" disable-transitions>已撤销</el-tag>
							<el-tag type="primary" v-else-if="scope.row.verify_status>0" disable-transitions>已完结</el-tag>
							<el-tag type="danger" v-else-if="scope.row.result_status" disable-transitions>已申诉</el-tag>
							<el-tag type="warning" v-else disable-transitions>待处理</el-tag>
						</div>
					</template>
				</el-table-column>
				<el-table-column prop="add_time" label="投诉时间" width="165"></el-table-column>
        <el-table-column fixed="right" label="操作" width="150px">
          <template slot-scope="scope">
            <el-row style="margin-bottom:10px" v-if="scope.row.verify_status===0&&scope.row.is_cancel===0">
              <el-button size="mini" type="primary" plain @click="goVerify(scope.row)">审核</el-button>
            </el-row>
            <el-row style="margin-bottom:10px" v-else>
              <el-button size="mini" type="success" plain @click="goVerify(scope.row)">查看</el-button>
            </el-row>
          </template>
        </el-table-column>
			</el-table>
			<!-- 分页 -->
			<Pagination :queryInfo="queryInfo" :total="total" @handleSizeChange="handleSizeChange" @handleCurrentChange="handleCurrentChange"></Pagination>
		</el-card>


    <el-dialog title="投诉审核" width="800px" top="5vh" :visible.sync="verifyDialog" :modal-append-to-body="false" center>
      <el-form ref="commonForm" :model="verifyForm" label-width="120px">
        <el-row>
          <el-row>
            <el-col :span="12">
              <el-form-item label="客户头像" prop="address">
                <el-image :src="verifyForm.wx.avatarUrl" style="width: 60px; height: 60px;"></el-image>
              </el-form-item>
            </el-col>
            <el-col :span="10">
              <el-form-item label="客户名称" prop="nickName">
                <el-input v-model="verifyForm.wx.nickName" disabled></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-col :span="12">
            <el-form-item label="服务类型" prop="type_name">
              <el-input v-model="verifyForm.type_name" disabled></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="10">
            <el-form-item label="服务项目" prop="service_name">
              <el-input v-model="verifyForm.service_name" disabled></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="总计" prop="pay_fee">
              <el-input v-model="verifyForm.pay_fee" disabled></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="10">
            <el-form-item label="评分" prop="level">
              <el-input v-model="verifyForm.level" disabled></el-input>
            </el-form-item>
          </el-col>

          <el-col :span="22">
            <el-form-item label="投诉内容" prop="service_name">
              <el-input type="textarea" :rows="5" maxlength="500" show-word-limit v-model="verifyForm.content" disabled></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="22">
            <el-form-item label="投诉时间" prop="add_time">
              <el-input v-model="verifyForm.add_time" disabled></el-input>
            </el-form-item>
          </el-col>

        <el-row v-if="verifyForm.result_status">
          <el-col :span="12">
            <el-form-item label="申诉人" prop="jishi_name">
              <el-input v-model="verifyForm.jishi_name" disabled></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="10">
            <el-form-item label="申诉时间" prop="result_time">
              <el-input v-model="verifyForm.result_time" disabled></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="22">
            <el-form-item label="申诉理由" prop="result_content">
              <el-input type="textarea" :rows="5" maxlength="500" show-word-limit v-model="verifyForm.result_content" disabled></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="22">
            <el-form-item label="附件" prop="result_img_list">
              <div style="display: flex; flex-wrap: wrap;">
                <el-image :src="item.img_path" :preview-src-list="verifyForm.result_img_list.map(item=>item.img_path)"
                          style="width: 120px; height: 120px; margin-right: 10px;margin-bottom: 10px;"
                          v-for="(item,index) in verifyForm.result_img_list" :key="index"></el-image>
              </div>
            </el-form-item>
          </el-col>
        </el-row>

          <div v-if="verifyForm.verify_status===0&&verifyForm.is_cancel===0">
            <el-col :span="22">
              <el-form-item label="审核结果" prop="verify_status">
                <el-radio v-model="submitForm.verify_status" label="1">通过</el-radio>
                <el-radio v-model="submitForm.verify_status" label="2">不通过</el-radio>
              </el-form-item>
            </el-col>
            <el-col :span="22">
              <el-form-item label="审核意见" prop="verify_reason">
                <el-input type="textarea" :rows="5" maxlength="300" show-word-limit v-model="submitForm.verify_reason" placeholder="请输入审核意见"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="22" style="text-align:center">
              <el-button type="default" @click="verifyDialog=false">取消</el-button>
              <el-button type="primary" @click="doSubmit">确定</el-button>
            </el-col>
          </div>
          <div v-else-if="verifyForm.is_cancel===0">
            <el-col :span="12">
              <el-form-item label="审核结果" prop="verify_status">
                <el-radio disabled v-model="verifyForm.verify_status" :label="1">通过</el-radio>
                <el-radio disabled v-model="verifyForm.verify_status" :label="2">不通过</el-radio>
              </el-form-item>
            </el-col>
            <el-col :span="22">
              <el-form-item label="审核意见" prop="verify_reason">
                <el-input type="textarea" disabled :rows="5" maxlength="300" show-word-limit v-model="verifyForm.verify_reason"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="22" style="text-align:center">
              <el-button type="default" @click="verifyDialog=false">取消</el-button>
            </el-col>
          </div>
        </el-row>
      </el-form>
    </el-dialog>


	</div>
</template>

<script>
	import Treeselect from "@riophae/vue-treeselect";

  let that;
	let timer = null;
	import Pagination from '@/components/Pagination/Pagination.vue'
	export default {
		data() {
			return {
				total: 0,
				queryInfo: {
					pagenum: 1,
					pagesize: 10,
				},
				searchText: "",
				jishi_name: "",
				datePicker: "",
				tousu_list: [],

        verifyDialog: false,
        verifyForm: {
          wx:{}
        },
        submitForm: {
          tousu_id: 0,
          verify_status: '1',
          verify_reason: '',
        },

			};
		},
		components: {
      Treeselect,
			Pagination
		},
		created() {
			that = this;
			if (!this.$store.state.userInfo) {
	        // 防止未登录
	        this.$router.push({path: '/login'})
	    }else{
					this.getTousuList();
			}
		},
		methods: {
			getTousuList() {
				let that = this;
				var url = 'order/tousu_list';
				let params = {
					order_sn: this.searchText,
					jishi_name: this.jishi_name,
					date_time: this.datePicker,
					size: this.queryInfo.pagesize,
					page: this.queryInfo.pagenum,
				};
				this.fd_post(url, params).then((res) => {
					if(res.status) {
						that.total = res.total;
						that.tousu_list = res.list;
						console.log(res.list);
					} else {
						that.$message.error(res.msg);
					}
				}).catch((err) => {
					that.$message.error('网络错误');
				});
			},
			search(){
				this.getTousuList();
			},
			// 重置
			reset() {
				this.searchText = "";
				this.jishi_name = "";
				this.datePicker = "";
				this.getTousuList();
			},
			//页数改变
			handleSizeChange(newSize) {
				this.queryInfo.pagenum = 1;
				this.queryInfo.pagesize = newSize;
				this.getTousuList();
			},
			//页码改变
			handleCurrentChange(newPage) {
				this.queryInfo.pagenum = newPage;
				this.getTousuList();
			},

      goVerify(item) {
        this.verifyForm = item;
        this.submitForm.tousu_id = item.tousu_id;
        this.verifyDialog = true;
      },
      doSubmit() {
        var url = 'order/tousuVerify';
        let params = this.submitForm;
        this.fd_post(url, params).then((res) => {
          this.verifyDialog = false;
          if(res.status) {
            this.$message.success("操作成功");
            this.getTousuList();
          } else {
            this.$message.error(res.msg);
          }
        }).catch((err) => {
          this.showDialog = false;
          this.$message.error('网络错误');
        });
      },

		},
	};
</script>

<style lang="scss" scoped>
	.user_yijian{
    text-align: left;
		.left{
		  text-align: left;
		  margin-bottom: 20px;
		}
		.demo-table-expand {
	    font-size: 0;
	  }
	  .demo-table-expand label {
	    width: 90px;
	    color: #99a9bf;
	  }
	  .demo-table-expand .el-form-item {
	    margin-right: 0;
	    margin-bottom: 0;
	    width: 50%;
	  }
	}
</style>